import React from 'react';
import { Descriptions, Space, Table } from 'antd';
import SectionTitle from '../../../components/SectionTitle';
import ImageGridPreviewPage from './ImageGridPreviewPage';
import moment from 'moment';


const CustomerResolutionPreview = ({ formData }) => {
    // If formData is undefined, provide an empty object as a fallback
    const data = formData || {};
    const DateFormat = 'MM-DD-YYYY';
    const columns = [
        {
            title: 'Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => date ? moment(date).format(DateFormat) : 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => amount || 'N/A',
        },
        {
            title: 'Install Date',
            dataIndex: 'installDate',
            key: 'installDate',
            render: (installDate) => installDate ? moment(installDate).format(DateFormat) : 'N/A',
        },
    ];
    const productColumns = [
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Back',
            dataIndex: 'back',
            key: 'back',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Lot',
            dataIndex: 'lot',
            key: 'lot',
            render: (text) => text || 'N/A',
        },
        {
            title: 'SY/SF',
            dataIndex: 'sysf',
            key: 'sysf',
            render: (text) => text || 'N/A',
        },
        {
            title: 'Unit Price',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (text) => text || 'N/A',
        },
    ];
    return (
        <Space className='field-service-resolution-form-preview' direction="vertical" size="large" style={{ width: '100%' }}>
            <SectionTitle level={3}>Claim Information</SectionTitle>
            <Descriptions bordered>
                <Descriptions.Item label="Claim Number">{data.claimNumber || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Request Date">
                    {data.requestDate ? moment(data.requestDate).format(DateFormat) : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item className='view-caps' label="Job Stop">{data.jobStop || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Claims Analyst">{data.claimsAnalyst || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Analyst Extension">{data.analystExtension || 'N/A'}</Descriptions.Item>
                <Descriptions.Item  className='view-caps' label="Turn Key Labor">{data.turnKeyLabor || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Customer Concern" span={3}>{data.customerConcern || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Project/Job Name">{data.projectJobName || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Account Executive">{data.accountExecutive || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Cell">{data.cell || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Hospitality Job Number">{data.hospitalityJobNumber || 'N/A'}</Descriptions.Item>
                
            </Descriptions>

            <SectionTitle level={3}>Contact Information</SectionTitle>
            <Descriptions bordered>
                <Descriptions.Item label="Dealer Contact">{data.dealerContact || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Site Contact">{data.siteContact || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Company">{data.company || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="City">{data.city || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="State">{data.state || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Primary Phone No">{data.primaryPhoneNo || 'N/A'}</Descriptions.Item>
                {/* <Descriptions.Item label="Alt Phone No">{data.altPhoneNo || 'N/A'}</Descriptions.Item> */}
                <Descriptions.Item label="Phone No">{data.phoneNo || 'N/A'}</Descriptions.Item>
            </Descriptions>

            <SectionTitle level={3}>Invoice Information</SectionTitle>

            {data.invoices && data.invoices.length > 0 ? (
                <div className='table-responsive'>
                    <Table
                        columns={columns}
                        dataSource={data.invoices}
                        rowKey={(record, index) => index}
                        pagination={false}
                    />
                </div>
            ) : (
                <p>No Invoices Added</p>
            )}
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Adhesive/Sealer/Underlayment on Invoice">
                    {data.adhesiveInfo || 'N/A'}
                </Descriptions.Item>
            </Descriptions>

            <SectionTitle level={3}>Product Information</SectionTitle>
            {data.products && data.products.length > 0 ? (
                <div className='table-responsive'>
                    <Table
                        columns={productColumns}
                        dataSource={data.products}
                        rowKey={(record, index) => index}
                        pagination={false}
                    />
                </div>
            ) : (
                <p>No Products Added</p>
            )}
            <SectionTitle level={3}>Inspection Information</SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="FS Manager">{data.fsManager || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Inspection Date">
                    {data.inspectionDate ? moment(data.inspectionDate).format(DateFormat) : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Project/Job Name">{data.projectJobName || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Persons & Companies Present">
                    {data.personsPresent || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Flooring Site Information</SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Commercial/Residential">{data.siteType || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Age of Building">{data.buildingAge || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Number of Occupants per Day">{data.occupantsPerDay || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Address">{data.address || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Building Description">{data.buildingDescription || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Type Grade">{data.typeGrade || 'N/A'}</Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Subfloor & Underlayment Details</SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Substrate/Subfloor Thickness">
                    {data.subfloorThickness ? `${data.subfloorThickness} inches` : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Underlayment">
                    {data.hasUnderlayment === 'yes' ? `Yes - ${data.underlaymentDescription}` : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Is Subfloor Flat?">
                    {data.isSubfloorFlat === 'yes' ?
                        `Yes - ${data.subfloorFlatExplanation}` :
                        data.isSubfloorFlat === 'no' ?
                            `No - ${data.subfloorFlatExplanation}` :
                            'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Subfloor Condition">
                    {data.subfloorCondition ?
                        data.subfloorCondition.charAt(0).toUpperCase() + data.subfloorCondition.slice(1) :
                        'N/A'}
                </Descriptions.Item>
            </Descriptions>

            <SectionTitle level={4}>Installation & Expansion Details</SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Installation Type">
                    {data.installationType ?
                        data.installationType.replace(/([A-Z])/g, ' $1').trim() :
                        'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Expansion">
                    {data.expansion || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="List Measurements">
                    {data.measurements || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Adhesive Details</SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Adhesive Type/Brand">
                    {data.adhesiveType || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Trowel Size/Shape (Depth, Width, Spacing)">
                    {data.trowelSize || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Adhesive Coverage">
                    {data.adhesiveCoverage || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Adhesive Bond">
                    {data.adhesiveBond || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Was Floor and/or Adhesive Properly Prepared?">
                    {data.floorPrepared || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="pH Reading of Floor">
                    {data.phReading || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Tramex Moisture Percentage">
                    {data.tramexMoisture || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="RH% (Relative Humidity)">
                    {data.relativeHumidity || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Indications of Moisture at Site">
                    {data.moistureIndications || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>HVAC & Acclimation Information </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Was HVAC On During Installation?">
                    {data.hvacDuringInstallation || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Is HVAC Turned Off During Inactive Hours?">
                    {data.hvacOffDuringInactive || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Flooring, Adhesive, Site Acclimated to Specifications Prior to Installation">
                    {data.acclimatedToSpecs || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Affected Areas Information </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Previous Installed Floor">
                    {data.previousFloor || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Areas Affected">
                    {data.areasAffected || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Room Affected">
                    {data.roomAffected || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Percent Floor Affected">
                    {data.percentFloorAffected || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Was Floor Rolled?">
                    {data.floorRolled || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Weight of Roller">
                    {data.rollerWeight || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Note if Affected Areas Are Just in Perimeter">
                    {data.affectedAreasPerimeter || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Maintenance Information </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Daily Cleaning">
                    {data.dailyCleaning || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Regular Cleaning (How Often?)">
                    {data.regularCleaning || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Chemical Used (Brand, Specific Product, pH)">
                    {data.chemicalUsed || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="How Long After Floor Installed Was it Maintained?">
                    {data.maintenanceDelay || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Condition of Floor">
                    {data.floorCondition || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Walk-off Mats">
                    {data.walkOffMats || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Other Conditions (e.g., Direct Sunlight)">
                    {data.otherConditions || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Furniture Details </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Systems Furniture">
                    {data.systemsFurniture || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Brand">
                    {data.furnitureBrand || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Model">
                    {data.furnitureModel || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Problem Description </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Description of Problem Area">
                    {data.problemDescription || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Manager's Opinion </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Is Claim Site Related?">
                    {data.isSiteRelated || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Is Claim Installation Related?">
                    {data.isInstallationRelated || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Is Claim Manufacturing Related?">
                    {data.isManufacturingRelated || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Are Samples Available?">
                    {data.areSamplesAvailable || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Digital Photos Included">
                    {data.digitalPhotos ? 'Yes' : 'No'}
                </Descriptions.Item>
                <Descriptions.Item label="Other Site Issues">
                    {data.otherSiteIssues || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Repair Recommendations </SectionTitle>
            <Descriptions bordered column={1}>
                <Descriptions.Item label="Repair Recommendations">
                    {data.repairRecommendations || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Can the Condition be Repaired?">
                    {data.canBeRepaired || 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Repair Solution/Reasoning or Replacement">
                    {data.repairSolution || 'N/A'}
                </Descriptions.Item>
            </Descriptions>
            <SectionTitle level={4}>Attachments </SectionTitle>
            <ImageGridPreviewPage uploadedImages={data.attachments} />
        </Space>
    );
};

export default CustomerResolutionPreview;