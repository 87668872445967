import React, { useState, useEffect, useCallback } from "react";
import {
  Table, Row, Col, Card, Divider, Input,
  Form, Button, Modal, Select, Tag, Space, message, Skeleton, Tooltip
} from "antd";
import { EyeFilled, SearchOutlined, UserOutlined, UserSwitchOutlined, FormOutlined, FileOutlined, MailOutlined, DeleteOutlined } from "@ant-design/icons";
import { IconButton } from "../../components/Buttons/buttons";
import * as GetFsmanagerPanelData from "../../services/fsmanagerPanel";
import { inspectorService } from "../../services/inspectorService";
import * as InspectionServices from '../../services/InspectionReports';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash/debounce';
import Swal from 'sweetalert2';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const FsmanagerPanelDataTable = () => {
  // State Management
  const [tableState, setTableState] = useState({
    page: 1,
    pageSize: 10,
    loading: true,
    inspections: [],
    filteredInspections: [],
    searchText: "",
  });

  const [modalState, setModalState] = useState({
    visible: false,
    loading: false,
    selectedRequest: null,
    inspectors: [],
    inspectorLoading: true,
  });
  const [commentData, setCommentData] = useState([]);
  const [statusValues, setStatusValues] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientDropdown, setClientDropdown] = useState([]);


  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch Data Functions
  const fetchFsmanagerPanelData = useCallback(async () => {
    try {
      setTableState(prev => ({ ...prev, loading: true }));
      const res = await dispatch(GetFsmanagerPanelData.getInspectionFsmanagerPanelData());

      const filtered = res.filter((inspections) => {
        return (
          inspections.status_hidden !== 'Cancelled' &&
          inspections.status_hidden !== 'Delete' &&
          inspections.status_hidden !== 'RFQ_deleted'
        );
      });
      setTableState(prev => ({
        ...prev,
        inspections: res,
        filteredInspections: filtered,
        loading: false,
      }));
    } catch (err) {
      console.error("Error fetching FS Manager data:", err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch inspection data',
      });
      setTableState(prev => ({ ...prev, loading: false }));
    }
  }, [dispatch]);

  // Add this to your component's functions
  const handleDeleteClaim = async (record) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete claim #${record.claim_number}. This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#6c757d',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary'
        }
      });

      if (result.isConfirmed) {
        setTableState(prev => ({ ...prev, loading: true }));
        let val = {};
        val.req_id = record.req_id;
        dispatch(GetFsmanagerPanelData.deletInspection(val))
          .then((res) => {
            if (res.data === 'success') {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: `Claim #${record.claim_number} has been deleted successfully.`,
                timer: 2000,
                showConfirmButton: false
              });
              setLoading(true);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.info('Get Error Edit State...');
          })

        // Refresh the table data
        await fetchFsmanagerPanelData();
      }
    } catch (error) {
      console.error('Error deleting claim:', error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete the claim. Please try again.',
      });
    } finally {
      setTableState(prev => ({ ...prev, loading: false }));
    }
  };
  const getCommentData = useCallback((req_id) => {
    setLoading(true);
    dispatch(InspectionServices.getComments(req_id))
      .then((res) => {
        setCommentData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching comments:', err);
        setLoading(false);
      });
  }, [dispatch]);
  const fetchInspectors = async () => {
    try {
      setModalState(prev => ({ ...prev, inspectorLoading: true }));
      const data = await inspectorService.getAllInspectors();
      setModalState(prev => ({
        ...prev,
        inspectors: data,
        inspectorLoading: false,
      }));
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch inspectors',
      });
      setModalState(prev => ({ ...prev, inspectorLoading: false }));
    }
  };
  const handleStatusChange = (value) => {
    form.setFieldsValue({ status: value });
  };
  // Event Handlers
  const handleSearch = useCallback(
    debounce((value) => {
      setTableState(prev => {
        const filtered = prev.inspections.filter((item) =>
          Object.values(item).some((val) =>
            String(val).toLowerCase().includes(value.toLowerCase())
          )
        );
        return {
          ...prev,
          searchText: value,
          filteredInspections: filtered,
        };
      });
    }, 300),
    []
  );
  const openStatusModal = (statusValues) => {
    setStatusValues(statusValues);
    getCommentData(statusValues.req_id);
    form.setFieldsValue({
      client: statusValues.client_name !== '-' ? statusValues.client_name : undefined,
      status: statusValues.status,
    });
    setStatusModal(true);
  };
  const openAssignModal = useCallback((record) => {

    setModalState(prev => ({
      ...prev,
      visible: true,
      selectedRequest: record,
    }));
    // Get the inspector's name from the list using their ID
    const selectedInspector = modalState.inspectors.find(inspector => inspector.id === record.fsinspector_id);
    form.setFieldsValue({
      fs_inspector: selectedInspector ? selectedInspector.id : undefined,  // Set inspector ID if exists
    });
  }, [form, modalState.inspectors]); // Ensure inspectors list is in dependencies
  const onFinish = (values) => {
    setLoading(true);
    values.pId = statusValues.req_id;
    values.notifyClient = form.getFieldValue('notifyClient') || false;
    values.inspection_date_status = values.inspection_date_status
      ? moment(values.inspection_date_status).format('MM-DD-YYYY')
      : '';

    dispatch(InspectionServices.updateStatus(values))
      .then(() => {
        message.success('Status updated!');
        form.resetFields();
        setStatusModal(false);
        fetchFsmanagerPanelData();
      })
      .catch((err) => {
        console.error('Error updating status:', err);
        message.error('Failed to update status');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAssignInspector = async (values) => {
    try {
      setModalState(prev => ({ ...prev, loading: true }));
      const response = await inspectorService.assignInspector(
        modalState.selectedRequest.req_id,
        values.fs_inspector
      );
      console.log({ response });
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Inspector assigned successfully',
      });

      await fetchFsmanagerPanelData();
      setModalState(prev => ({ ...prev, visible: false }));
      form.resetFields();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to assign inspector: ' + error.message,
      });
    } finally {
      setModalState(prev => ({ ...prev, loading: false }));
    }
  };

  // Effects
  useEffect(() => {
    fetchFsmanagerPanelData();
    return () => handleSearch.cancel();
  }, [fetchFsmanagerPanelData, handleSearch]);

  useEffect(() => {
    if (modalState.visible) {
      fetchInspectors();
    }
  }, [modalState.visible]);

  const InspectorCell = ({ text, onClick }) => (
    <Tooltip title="Click to assign inspector">
      <div
        className="d-flex align-items-center gap-2 px-3 py-2 rounded cursor-pointer assign-inspector-group"
        onClick={onClick}
      >
        <UserOutlined className="text-secondary assign-inspector-icon" />
        <span className="text-dark font-weight-medium assign-inspector-text">
          {text || "Unassigned"}
        </span>
        <UserSwitchOutlined
          className="opacity-0 ml-2 text-primary assign-inspector-switch"
        />
      </div>
    </Tooltip>


  );
  // Table Columns Configuration
  const columns = [
    {
      title: "S.No",
      key: "index",
      width: 80,
      render: (_, __, index) => (tableState.page - 1) * tableState.pageSize + index + 1,
    },
    {
      title: "Claim #",
      dataIndex: "claim_number",
      key: "claim_number",
      sorter: (a, b) => a.claim_number.localeCompare(b.claim_number),
      render: (text) => <span className="tw-font-medium">{text}</span>,
    },
    {
      title: "FS Inspector",
      dataIndex: "fsinspector_name",
      key: "fsinspector_name",
      sorter: (a, b) => a.fsinspector_name.localeCompare(b.fsinspector_name),
      render: (text, record) => (
        <InspectorCell
          text={text}
          onClick={() => openAssignModal(record)}
        />
      ),
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      sorter: (a, b) => moment(a.request_date).diff(moment(b.request_date)),
      render: (date) => moment(date).format('MMM DD, YYYY'),
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: "Status",
      dataIndex: "status_hidden",
      key: "status_hidden",
      render: (text, record) => (
        <Tag
          color={getStatusColor(text)}
          className="tw-px-4 tw-py-1 tw-cursor-pointer fsmanagerPanel-status-btn "
          onClick={() => openStatusModal(record.status)}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (_, record) => (
        <Space>
          <IconButton
            icon={<EyeFilled />}
            type="primary"
            tooltip="View Request"
            handleClick={() => navigate(`/single-request/?req_id=${record.action.req_id}`)}
          />
          {record.action.report_generated === 'YES' && record.action.reportId ? (
            <IconButton
              icon={<FormOutlined />}
              type="primary"
              tooltip="Edit Report"
              text="Report"
              handleClick={() => navigate(`/field-service-resolution-form/?report_id=${record.action.reportId}`)}
            />
          ) : (
            <IconButton
              icon={<FileOutlined />}
              type="primary"
              tooltip="Create Report"
              text="Report"
              handleClick={() => navigate(`/field-service-resolution-form/?req_id=${record.action.req_id}`)}
            />
          )}
          {record.action.report_generated === 'YES' && record.action.reportId ? (
            <IconButton
              icon={<MailOutlined />}
              type="primary"
              tooltip="Send Email"
              text="Email"
              handleClick={() =>
                navigate('/send-email/?req_id=' + record.action.req_id)
              }
            />
          ) : (
            ''
          )}
          <IconButton
            icon={<DeleteOutlined />}
            type="danger"
            tooltip="Delete Claim"
            handleClick={() => handleDeleteClaim(record)}
            className="delete-btn"
          />
        </Space>
      ),
    },
  ];


  // Helper Functions
  const getStatusColor = (status) => {
    const statusColors = {
      'Pending': 'orange',
      'Assigned': 'green',
      'In Progress': 'blue',
      'Completed': 'cyan',
    };
    return statusColors[status] || 'default';
  };

  return (
    <div className="p-6">
      <Card className="shadow-md rounded-lg fs-manager-claim-listing">
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          <Col xs={24} md={12}>
            <div className="space-y-2">
              <p className="tableHeaderText">FS Manager Claim Listing</p>
              <Tag color="blue" className="text-base totalClaims">
                Total Claims: {tableState.filteredInspections.length}
              </Tag>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Input
              size="large"
              className="search-input fs-managerPanel-search-input"
              prefix={<SearchOutlined className="text-gray-400" />}
              allowClear
              placeholder="Search claims..."
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
        </Row>

        <Divider />

        <Table
          className="mt-4"
          columns={columns}
          dataSource={tableState.filteredInspections}
          loading={tableState.loading}
          rowKey="req_id"
          pagination={{
            current: tableState.page,
            pageSize: tableState.pageSize,
            onChange: (page, pageSize) =>
              setTableState(prev => ({ ...prev, page, pageSize })),
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          scroll={{ x: 'max-content' }}
        />
      </Card>

      <Modal
        title={
          <div className="d-flex align-items-center gap-3 px-2 py-1">
            <UserSwitchOutlined className="text-primary fs-4" />
            <span className="h5 mb-0 text-dark">Assign Inspector</span>
          </div>
        }
        open={modalState.visible}
        onCancel={() => {
          setModalState(prev => ({ ...prev, visible: false }));
          form.resetFields();
        }}
        footer={null}
        width={600}
        centered
      >
        <Form
          form={form}
          onFinish={handleAssignInspector}
          layout="vertical"
          className="px-2"
        >
          <Form.Item
            label={<span className="text-secondary font-weight-medium">Select Inspector</span>}
            name="fs_inspector"
            className="mb-0"
            rules={[{ required: true, message: 'Please select an inspector' }]}
          >
            <Select
              placeholder="Choose an inspector"
              loading={modalState.inspectorLoading}
              size="large"
              showSearch
              optionFilterProp="children"
              className="w-100"
              dropdownStyle={{ maxHeight: 400 }}
            >
              {modalState.inspectors.map((inspector) => (
                <Option value={inspector.id} key={inspector.id}>
                  {inspector.name || "Unnamed Inspector"}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div className="d-flex justify-content-end gap-2 mt-4">
            <Button
              className="rounded px-3"
              onClick={() => {
                setModalState(prev => ({ ...prev, visible: false }));
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="rounded assign-inspector-btn"
              loading={modalState.loading}
              onClick={form.submit}
            >
              Assign Inspector
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Change Status"
        centered
        open={statusModal}
        className="inspectionCategoryModal industryModal"
        width={720}
        onOk={form.submit}
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={form.submit}
            loading={loading}
          >
            Save Changes
          </Button>,
          <Button key="cancel" className="cancelBtn" onClick={() => setStatusModal(false)}>
            Cancel
          </Button>,
        ]}
        onCancel={() => setStatusModal(false)}
      >
        <Form
          form={form}
          name="statusForm"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Client" name="client">
                <Select
                  disabled={statusValues.req_type === 'Flooring Manufacturer' || statusValues.client_name !== '-'}
                >
                  {clientDropdown.map((option) => (
                    <Option value={option.id} key={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Status" name="status">
                <Select onChange={handleStatusChange}>
                  <Option value="Pending">Pending</Option>
                  <Option value="In Progress">In Progress</Option>
                  <Option value="Completed">Completed</Option>
                  <Option value="Draft">Draft</Option>
                  <Option value="Cancelled">Cancelled</Option>
                  <Option value="More info requested">More info requested</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item label="Comments" name="comments">
                <TextArea
                  placeholder="Comments"
                  className="customTextarea formControl"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="commentsSection">
          <div className="notes-show-section">
            <ul className="notes-list">
              {loading ? (
                <Skeleton active />
              ) : (
                commentData.data?.map((comment, index) => (
                  <li className="notes-items" key={index}>
                    <div className="note-content">
                      <p dangerouslySetInnerHTML={{ __html: comment.comment_content }}></p>
                    </div>
                    <p className="notes-meta">
                      {comment.comment_date}
                      <span className="mx-2">{comment.comment_author}</span>
                    </p>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FsmanagerPanelDataTable;