import React, { useState, useEffect } from 'react';
import {
  Table,
  Tag,
  Input,
  Row,
  Col,
  Card,
  Select,
  DatePicker,
  Modal,
  Form,
  Button,
  Checkbox,
  Empty,
  message,
  notification,
  Skeleton,
  InputNumber,
  Switch,
} from 'antd';
import { IconButton } from '../components/Buttons/buttons';
import {
  BookFilled,
  BookOutlined,
  DownOutlined,
  EyeFilled,
  FileAddOutlined,
  FileDoneOutlined,
  FileOutlined,
  FormOutlined,
  MailOutlined,
  PictureOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import * as InspectionServices from '../services/InspectionReports';
import * as getClientdropService from '../services/getClientdrop';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Actions from '../store/actions/inspection';
import moment from 'moment';
import Swal from 'sweetalert2';
import { DeleteOutlined } from '@mui/icons-material';

const { Option } = Select;
const { TextArea } = Input;

function InspectionRequestData(props) {
  var dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [reqId, setReqId] = useState();
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [inspections, setInspections] = useState([]);
  const [filterdinspections, setFilterdInspections] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isActive, setIsActive] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalInspection, setModalInspection] = useState(false);
  const [searchedText, setSearchedText] = useState('');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');
  const [clientDropdown, setclientDropdown] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const [commentData, setCommentData] = useState([]);
  const [checked, setChecked] = useState(true);
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [schedule, setSchedule] = useState(false);
  const reduxData = useSelector((state) => state.Inspection.reportDetails);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [hideQuotationField, setHideQuotationField] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reqData, setReqData] = useState(null);

  const defaultLogo = "https://www.floordetective.com/wp-content/uploads/2021/09/logo_tm.png";


  useEffect(() => {
    autoSaveddata();
    fetchData();
    fetchclientData();
    window.scrollTo(0, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeKey]);
  const handleSwitchChange = (checked) => {
    setHideQuotationField(checked);
  };
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    scheduleChange(value);
  };

  const autoSaveddata = () => {
    if (
      Object.keys(reduxData).length !== 0 &&
      reduxData.constructor === Object &&
      reduxData.currentUrl !== ''
    ) {
      Swal.fire({
        title: 'Do you want to continue on your last unsaved report?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: `Discard Report`,
        cancelButtonText: `Ask Me Later`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          window.location.href = reduxData.currentUrl;
        } else if (result.isDenied) {
          dispatch(Actions.create_report({}));
        }
      });
    }
  };

  const modalStyles = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px', // Rounded corners
    width: '600px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow
  };

  const headerStyles = {
    borderBottom: '2px solid #0A497B', // Accent color for header
    paddingBottom: '10px',
    marginBottom: '20px',
    fontSize: '24px',
    color: '#0A497B',
  };

  const bodyStyles = {
    padding: '0 10px',
  };

  const logoContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const logoStyles = {
    width: '180px',
    height: 'auto',
  };

  const sectionStyles = {
    marginBottom: '20px',
  };

  const sectionTitleStyles = {
    fontSize: '18px',
    color: '#333',
    marginBottom: '10px',
    textDecoration: 'underline',
  };

  const dividerStyles = {
    border: 'none',
    borderTop: '1px solid #ccc',
    margin: '20px 0',
  };
  const scheduleChange = (value) => {
    if (value === 'Schedule') {
      setSchedule(true);
    } else if (value === 'Delete') {
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure you want to delete permanently?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // onFinish();

        }
      });
    } else {
      setSchedule(false);
    }
  };
  const showLimitedReq = (reqId) => {
    setIsModalVisible(true);
    setLoading(true);
    dispatch(InspectionServices.getLimitedReq(reqId))
      .then((res) => {
        console.warn('result from update status api', res);
        setReqData(res.data); // Save the API result
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };
  const onChangeStartDate = (date, dateString) => {
    setFilterStartDate(moment(dateString).format('MM-DD-YYYY'));
    let EndDate = filterEndDate ? filterEndDate : moment().format('MM-DD-YYYY');
    FilterAccordingDate(moment(dateString).format('MM-DD-YYYY'), EndDate);
  };

  const onChangeEndDate = (date, dateString) => {
    console.log(date, dateString);
    setFilterEndDate(moment(dateString).format('MM-DD-YYYY'));
    let startDate = filterStartDate
      ? filterStartDate
      : moment().format('MM-DD-YYYY');
    FilterAccordingDate(startDate, moment(dateString).format('MM-DD-YYYY'));
  };

  const FilterAccordingDate = (startdate, enddate) => {
    let datefilter = [];
    inspections.forEach((inspection) => {
      if (
        moment(inspection.request_date).isSameOrAfter(moment(startdate)) &&
        moment(inspection.request_date).isSameOrBefore(moment(enddate))
      ) {
        datefilter.push(inspection);
      }
    });
    setFilterdInspections(datefilter);
  };

  const handleChange = (newValue) => {
    let res = inspections;
    if (newValue === 'complete') {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === 'Complete';
      });
      setFilterdInspections(filtered);
    } else if (newValue === 'pending') {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === 'Pending';
      });
      setFilterdInspections(filtered);
    } else if (newValue === 'draft') {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === 'Draft';
      });
      setFilterdInspections(filtered);
    } else if (newValue === 'cancelled') {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === 'Cancelled';
      });
      setFilterdInspections(filtered);
    } else if (newValue === 'rfq') {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === 'RFQ_declined';
      });
      setFilterdInspections(filtered);
    } else {
      setFilterdInspections(inspections);
    }
    setValue(newValue);
  };

  const onFinish = (values) => {
    values.pId = statusValues.req_id;
    values.notifyClient = checked;
    values['inspection_date_status'] = values.inspection_date_status
      ? moment(values.inspection_date_status).format('MM-DD-YYYY')
      : '';
    dispatch(InspectionServices.updateStatus(values))
      .then((res) => {
        fetchData();
        message.success('Status updated!');
        form.resetFields();
        setStatusModal(false);
        console.warn('result from update status api', res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log('Failed:', errorInfo);
  };

  const openStatusModal = (statusValues) => {
    setCommentData([]);
    setStatusValues(statusValues);
    setSelectedStatus(statusValues.status);
    getCommentdata(statusValues.req_id);
    setSchedule(statusValues.status === 'Schedule');
    form.setFieldsValue({
      client:
        statusValues.client_name !== '-'
          ? statusValues.client_name
          : 'Select Client',
      status: statusValues.status,
    });
    setStatusModal(true);
  };

  const handleCancel = () => {
    setStatusModal(false);
    setIsModalVisible(false);
  };

  const getCommentdata = (req_id) => {
    setLoading(true);
    dispatch(InspectionServices.getComments(req_id))
      .then((res) => {
        setCommentData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const reportModalOpen = (req_id) => {
    setReqId(req_id);
    setModalInspection(true);
  };

  const viewReport = (action) => {
    setReqId(action.req_id);
    action.category === 'Carpet -Basic'
      ? navigate('/single-carpet-basic/?report_id=' + action.report_id)
      : action.category === 'Carpet'
        ? navigate('/single-carpet-detailed/?report_id=' + action.report_id)
        : action.category === 'Hard Surface -Basic'
          ? navigate('/single-hardwood-basic/?report_id=' + action.report_id)
          : action.category === 'LVT'
            ? navigate('/single-lvt/?report_id=' + action.report_id)
            : action.category === 'Hardwood'
              ? navigate('/single-hardwood-detailed/?report_id=' + action.report_id)
              : action.category === 'Tile'
                ? navigate('/single-tile-report/?report_id=' + action.report_id)
                : action.category === 'Laminate'
                  ? navigate('/single-Laminate/?report_id=' + action.report_id)
                  : action.category === 'NWFA Installation'
                    ? navigate('/single-nwfa-installation/?report_id=' + action.report_id)
                    : action.category === 'NWFA Sand Finish'
                      ? navigate('/single-nwfa-sandfinish/?report_id=' + action.report_id)
                      : navigate('/single-quick-report/?report_id=' + action.report_id);
  };


  const handleSubmit = (e, index) => {
    setLoadings(true);
    let res = {};
    res.data = {
      req_id: reqId,
      floor_category: value,
    };
    dispatch(Actions.create_manual_req(res));
    dispatch(Actions.create_report({}));
    e.preventDefault();
    let navTo = getCategorytitle(value);
    navigate(`/${navTo}`);
    setLoadings(false);
  };

  const getCategorytitle = (value) => {
    if (value === 'Quick Report') {
      return 'quickReport';
    } else if (value === 'Carpet -Basic') {
      return 'carpetBasic';
    } else if (value === 'Carpet') {
      return 'carpetDetailed';
    } else if (value === 'Hard Surface -Basic') {
      return 'hardwoodBasic';
    } else if (value === 'Hardwood') {
      return 'hardwoodDetailed';
    } else if (value === 'LVT') {
      return 'LVT';
    } else if (value === 'Tile') {
      return 'Tile';
    } else if (value === 'Laminate') {
      return 'Laminate';
    } else if (value === 'NWFA Sand Finish') {
      return 'nwfa-sand-finish-inspection-report';
    } else if (value === 'NWFA Installation') {
      return 'nwfa-installation-inspection-report';
    } else {
      return 'N/A';
    }
  };

  const options = [
    {
      id: 1,
      value: 'Quick Report',
      label: 'Quick Report',
    },
    {
      id: 2,
      value: 'Carpet -Basic',
      label: 'Carpet -Basic',
    },
    {
      id: 3,
      value: 'Carpet',
      label: 'Carpet -Detailed',
    },
    {
      id: 4,
      value: 'Hard Surface -Basic',
      label: 'Hardwood -Basic',
    },
    {
      id: 5,
      value: 'Hardwood',
      label: 'Hardwood -Detailed',
    },
    {
      id: 6,
      value: 'LVT',
      label: 'LVT/Carpet Tile',
    },
    {
      id: 7,
      value: 'Tile',
      label: 'Tile',
    },
    {
      id: 8,
      value: 'Laminate',
      label: 'Laminate',
    },
    {
      id: 9,
      value: 'NWFA Sand Finish',
      label: 'NWFA Sand Finish',
    },
    {
      id: 10,
      value: 'NWFA Installation',
      label: 'NWFA Installation',
    },
  ];

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(InspectionServices.getInspection())
      .then((res) => {
        let active_key = props.activeKey;
        if (active_key === '5') {
          const filtered = res.filter((inspections) => {
            return (
              inspections.status_hidden === 'Complete' ||
              inspections.status_hidden === 'Completed'
            );
          });
          setInspections(filtered);
        } else {
          const filtered = res.filter((inspections) => {
            return (
              inspections.status_hidden !== 'Complete' &&
              inspections.status_hidden !== 'Cancelled' &&
              inspections.status_hidden !== 'Completed' &&
              inspections.status_hidden !== 'Delete' &&
              inspections.status_hidden !== 'RFQ_deleted'
            );
          });
          setInspections(filtered);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
        setLoading(false);
      });
  };

  const fetchclientData = (params = {}) => {
    dispatch(getClientdropService.getClientdrop())
      .then((res) => {
        setclientDropdown(res);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      form.submit();
    }, 1000);
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const onChangenotify = (e) => {
    console.log('checked = ', e.target.checked);
    setChecked(e.target.checked);
  };

  const columns = [
    {
      title: 'S.No.',
      dataIndex: 'index',
      key: 'index',
      render: (value, item, index) => <>{(page - 1) * pageSize + index + 1}</>,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      hidden: true,
      sorter: (a, b) => {
        a = a.invoice_number || '';
        b = b.invoice_number || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Claim No.',
      dataIndex: 'claim_number',
      key: 'claim_number',
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.invoice_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.claim_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.client_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.customer_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.request_date)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.category).toLowerCase().includes(value.toLowerCase()) ||
          String(record.status_hidden)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ins_date).toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => {
        a = a.claim_number || '';
        b = b.claim_number || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Commissioning Party',
      dataIndex: 'client_name',
      key: 'client_name',
      sorter: (a, b) => {
        a = a.client_name || '';
        b = b.client_name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Homeowner/End User',
      dataIndex: 'customer_name',
      key: 'customer_name',
      sorter: (a, b) => {
        a = a.customer_name || '';
        b = b.customer_name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Inspection Date',
      dataIndex: 'ins_date',
      key: 'ins_date',
      sorter: {
        compare: (a, b) => a.ins_date.length - b.ins_date.length,
      },
    },
    {
      title: 'Request Date',
      dataIndex: 'request_date',
      key: 'request_date',
      sorter: (a, b) => {
        a = a.request_date || '';
        b = b.request_date || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: {
        compare: (a, b) => a.category.length - b.category.length,
      },
    },
    {
      title: 'Status',
      dataIndex: 'status_hidden',
      key: 'status_hidden',
      sorter: (a, b) => {
        a = a.status_hidden || '';
        b = b.status_hidden || '';
        return a.localeCompare(b);
      },
      render: (text, action) => (
        <>
          {action.action.rfq_choice === 'Yes' &&
            (action.action.rfq_status === 'RFQ_declined' || action.action.rfq_status === '') &&
            action.action.rfq_deleted !== true ? (
            <>
              <Tag
                color="#ffffff"
                className="statusTags"
                onClick={() => openStatusModal(action.status)}
              >
                {action.status_hidden === 'RFQ_declined' ? 'RFQ declined' : action.action.status}
              </Tag>
              <Button
                className="deleteBtn"
                icon={<DeleteOutlined />}
                onClick={() => inspDelete(action.action.req_id)}
              ></Button>
            </>
          ) : (
            <Tag
              color="#ffffff"
              className="statusTags"
              onClick={() => openStatusModal(action.status)}
            >
              {action.status_hidden}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',

      render: (i, action) => {
        if (
          action.action.rfq_choice !== 'Yes' ||
          (action.action.rfq_choice === 'Yes' &&
            action.action.rfq_status === 'RFQ_accepted')
        ) {
          return (
            <div className="actionBtn">
              {action.action.req_type === 'Manual request' ? (
                ''
              ) : (
                <IconButton
                  icon={<EyeFilled />}
                  type="primary"
                  tooltip="View"
                  text="View"
                  handleClick={() =>
                    navigate('/single-request/?req_id=' + action.action.req_id)
                  }
                />
              )}
              {action.action.report_generated === 'YES' ? (
                <IconButton
                  icon={<FormOutlined />}
                  type="primary"
                  tooltip="Edit Report"
                  text="Report"
                  handleClick={() => viewReport(action.action)}
                />
              ) : (
                <IconButton
                  icon={<FileOutlined />}
                  type="primary"
                  tooltip="Create Report"
                  text="Report"
                  handleClick={() => reportModalOpen(action.action.req_id)}
                />
              )}
              {action.action.invoice_generated === 'NO' ? (
                <IconButton
                  icon={<FileAddOutlined />}
                  type="primary"
                  tooltip="Create Invoice"
                  text="Invoice"
                  handleClick={() =>
                    navigate('/create-invoice/?req_id=' + action.action.req_id)
                  }
                />
              ) : (
                <IconButton
                  icon={<FileDoneOutlined />}
                  type="primary"
                  tooltip="Edit Invoice"
                  text="Invoice"
                  handleClick={() =>
                    navigate(
                      '/report-invoice/?invoice_id=' + action.action.invoice_id
                    )
                  }
                />
              )}
              {action.action.report_generated === 'YES' ? (
                <IconButton
                  icon={<PictureOutlined />}
                  type="primary"
                  tooltip="Photos"
                  text="Photos"
                  handleClick={() =>
                    navigate(
                      '/attachments/?report_id=' + action.action.report_id
                    )
                  }
                />
              ) : (
                <IconButton
                  icon={<PictureOutlined />}
                  type="primary"
                  tooltip="Photos"
                  text="Photos"
                  handleClick={() =>
                    navigate('/attachments/?req_id=' + action.action.req_id)
                  }
                />
              )}
              {action.action.cover_generated === 'NO' ? (
                <IconButton
                  icon={<BookOutlined />}
                  type="primary"
                  tooltip="Create Cover Letter"
                  text="Cover Letter"
                  handleClick={() =>
                    navigate(
                      '/create-cover-letter/?req_id=' + action.action.req_id
                    )
                  }
                />
              ) : (
                <IconButton
                  icon={<BookFilled />}
                  type="primary"
                  tooltip="Edit Cover Letter"
                  text="Cover Letter"
                  handleClick={() =>
                    navigate(
                      '/cover-letter/?cover_id=' + action.action.cover_id
                    )
                  }
                />
              )}
              {action.action.report_generated === 'YES' ? (
                <IconButton
                  icon={<MailOutlined />}
                  type="primary"
                  tooltip="Send Email"
                  text="Email"
                  handleClick={() =>
                    navigate('/send-email/?req_id=' + action.action.req_id)
                  }
                />
              ) : (
                ''
              )}
            </div>
          );
        } else if (action.action.status === 'RFQ_declined') {
          return 'RFQ declined.';
        } else if (action.action.status === 'RFQ_response') {
          return 'Requested for the Quote.';
        } else {
          return (
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="Request for quote is pending"
              text="View"
              handleClick={() => showLimitedReq(action.action.req_id)}
            />
          );
        }
      },
    },
  ].filter((item) => !item.hidden);

  const inspDelete = (req_id) => {
    console.info({ req_id });
    let val = {};
    val.req_id = req_id;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?!',
      text: "You won't be able to revert this!.",
      showCancelButton: true,
      confirmButtonText: `Yes, Delete It!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        dispatch(InspectionServices.deletInspection(val))
          .then((res) => {
            if (res.data === 'success') {
              notification.open({
                message: 'Inspection Deleted!',
                description: 'You have successfully deleted an inspection.',
                icon: (
                  <CheckCircleOutlined
                    style={{
                      color: '#108ee9',
                    }}
                  />
                ),
              });
              setLoading(true);
            }
            setLoading(false);
          })
          .catch((err) => {
            console.info('Get Error Edit State...');
          });
      }
    });
  };

  return (
    <div className="tableWrapper">
      <div className="filterWrap">
        <Row gutter={16} justify="space-between">
          <Col md={6} xs={24}>
            <div className="searchWrapper">
              <Input
                className="formControl"
                prefix={<SearchOutlined />}
                allowClear
                placeholder="Search..."
                onSearch={(value) => {
                  setSearchedText(value);
                }}
                onChange={(e) => {
                  setSearchedText(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col md={4} xs={24}>
            <div className="filterDropdown text-end">
              <button
                type="button"
                className={isActive ? 'btnActive filterBtn' : 'filterBtn'}
                onClick={handleClick}
              >
                Filter <DownOutlined className="dropIcon" />
              </button>
            </div>
          </Col>
          <Col md={24}>
            {isActive ? (
              <div className="filterSection">
                <Card className="filterCard">
                  <Row gutter={16} justify="center">
                    <Col span={4}>
                      <div className="formGroup">
                        <Input
                          placeholder="Claim Number"
                          className="formControl"
                          allowClear
                          onSearch={(value) => {
                            setSearchedText(value);
                          }}
                          onChange={(e) => {
                            setSearchedText(e.target.value);
                          }}
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="formGroup">
                        <Select defaultValue="Status" onChange={handleChange}>
                          <Option value="all">All</Option>
                          <Option value="pending">Pending</Option>
                          <Option value="complete">Complete</Option>
                          <Option value="draft">Draft</Option>
                          <Option value="cancelled">Cancelled</Option>
                          <Option value="rfq">RFQ Declined</Option>
                          <Option value="More info requested">More info requested</Option>
                        </Select>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="formGroup">
                        <DatePicker
                          name="start_date"
                          onChange={onChangeStartDate}
                          placeholder="Date From"
                          format="MM/DD/YYYY"
                        />
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="formGroup">
                        <DatePicker
                          name="end_date"
                          onChange={onChangeEndDate}
                          placeholder="Date To"
                          format="MM/DD/YYYY"
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
      <Table
        bordered
        className="inspectionDataTable"
        tableLayout="auto"
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={
          filterdinspections
            ? filterdinspections
            : inspections
        }
        locale={{
          emptyText: loading ? (
            columns.map((u) => (
              <Skeleton.Input
                height={50}
                style={{ marginTop: '2px', height: '50px', padding: '0px' }}
                block={true}
                active={true}
              />
            ))
          ) : (
            <Empty />
          ),
        }}
        rowKey={(record) => record.inspections}
        showSorterTooltip={false}
        pagination={{
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
          position: ['bottomCenter'],
        }}
      />

      {/* Status Modal */}

      <Modal
        title="Change Status"
        centered
        open={statusModal}
        className="inspectionCategoryModal industryModal"
        width={720}
        onOk={handleOk}
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={handleOk}
            loading={loading}
          >
            Save Changes
          </Button>,
          <Button key="cancel" className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <div className="modalContent">
          <Form
            form={form}
            name="statusForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item label="Client" name="client">
                  <Select
                    defaultValue="Select Client"
                    disabled={
                      statusValues.req_type === 'Flooring Manufacturer' ||
                        statusValues.client_name !== '-'
                        ? true
                        : false
                    }
                  >
                    {clientDropdown ? (
                      clientDropdown.map((option, id) => (
                        <Option value={option.id} key={id}>
                          {option.name}
                        </Option>
                      ))
                    ) : (
                      <Option value="No data">
                        <Empty />
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Status" name="status">
                  <Select
                    defaultValue="Change Status"
                    onChange={handleStatusChange}
                    disabled={
                      statusValues.rfq_choice === 'Yes' &&
                        statusValues.rfq_status !== 'RFQ_accepted'
                        ? true
                        : false
                    }
                  >
                    <Option value="RFQ_response">RFQ Reply</Option>
                    <Option value="Schedule">Schedule</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="In Progress">In Progress</Option>
                    <Option value="Completed">Completed</Option>
                    <Option value="Draft">Draft</Option>
                    <Option value="Delete">Delete</Option>
                    <Option value="Cancelled">Cancelled</Option>
                    <Option value="More info requested">More info requested</Option>
                  </Select>
                </Form.Item>
              </Col>
              {schedule && (
                <Col xs={24} md={24}>
                  <Form.Item
                    label="Inspection Date"
                    name="inspection_date_status"
                    rules={[
                      {
                        required: schedule,
                        message: 'Please input inspection date!'
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Inspection Date"
                      format="MM/DD/YYYY"
                    />
                  </Form.Item>
                </Col>
              )}

              {(selectedStatus === 'RFQ_response' || selectedStatus === 'RFQ Reply') && statusValues.rfq_choice === 'Yes' && (

                <Col xs={24} md={12}>
                  <Form.Item label={"Comment without quote"}>
                    <Switch checked={hideQuotationField} onChange={handleSwitchChange} />
                  </Form.Item>
                </Col>
              )}
              {!hideQuotationField && (selectedStatus === 'RFQ_response' || selectedStatus === 'RFQ Reply') && statusValues.rfq_choice === 'Yes' && (

                <Col xs={24} md={12}>
                  <Form.Item
                    label="Quotation Amount"
                    name="quotation_amount"
                    rules={[{ required: true, message: 'Please input the quotation amount!' }]}
                  >
                    <InputNumber
                      min={0}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      placeholder="Enter quotation amount"
                      className="formControl"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={24}>
                <Form.Item label="Comments" name="comments">
                  <TextArea
                    placeholder="Comments"
                    className="customTextarea formControl"
                    rows={4}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item name="notify-client">
                  <Checkbox onChange={onChangenotify} checked={checked}>
                    Notify Manufacturer/Client
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="commentsSection">
              <div className="notes-show-section">
                <ul className="notes-list">
                  {loading ? (
                    <Skeleton active />
                  ) : (
                    <>
                      {commentData &&
                        commentData.data?.map((comments, index) => (
                          <li className="notes-items" key={index}>
                            <div className="note-content">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: comments?.comment_content,
                                }}
                              ></p>
                            </div>
                            <p className="notes-meta">
                              {comments?.comment_date}
                              <span className="mx-2">
                                {comments?.comment_author}
                              </span>
                            </p>
                          </li>
                        ))}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </Form>
        </div>
      </Modal>

      {/* </>
      )} */}
      {/* Inspection Modal */}
      <Modal
        title="Floor Category"
        centered
        open={modalInspection}
        onCancel={() => setModalInspection(false)}
        className="inspectionCategoryModal"
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={handleSubmit}
            loading={loadings}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <Select
              defaultValue="Select Floor Category"
              onChange={handleChange}
              value={value ? value : 'Select Floor Category'}
            >
              {options.map((option) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>

      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={600}
        style={modalStyles}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          reqData && (
            <div className="modal-overlay">
              <div className="modal-content">
                <div className="modal-header" style={headerStyles}>
                  <h2>Request for quotation</h2>
                </div>

                <div className="modal-body" style={bodyStyles}>
                  {/* Manufacturer Logo */}
                  <div className="manufacturer-logo" style={logoContainerStyles}>
                    <img
                      src={reqData.manufacturer_logo || defaultLogo}
                      alt="Manufacturer Logo"
                      style={logoStyles}
                    />
                  </div>

                  {/* Claim Details */}
                  <div style={sectionStyles}>
                    <h4 style={sectionTitleStyles}>Claim Details</h4>
                    <p><strong>Claim Number:</strong> {reqData.claim_number}</p>
                    <p><strong>POA:</strong> {reqData.purchase_order}</p>
                  </div>

                  <hr style={dividerStyles} />

                  {/* Manufacturer Details */}
                  <div style={sectionStyles}>
                    <h4 style={sectionTitleStyles}>Manufacturer Details</h4>
                    <p><strong>Name:</strong> {reqData.manufacturer_name}</p>
                    <p><strong>Email Address:</strong> {reqData.manufacturer_email}</p>
                    <p><strong>Contact Person:</strong> {reqData.manufacturer_contact}</p>
                    <p><strong>Phone Number:</strong> {reqData.manufacturer_phone_number}</p>
                  </div>

                  <hr style={dividerStyles} />

                  {/* Inspection Site */}
                  <div style={sectionStyles}>
                    <h4 style={sectionTitleStyles}>Inspection Site</h4>
                    <p><strong>Address:</strong> {reqData.eu_city}, {reqData.eu_state}, {reqData.eu_country}</p>
                    <p><strong>ZIP Code:</strong> {reqData.eu_zip}</p>
                  </div>

                  <hr style={dividerStyles} />

                  {/* Products to Inspect */}
                  <div style={sectionStyles}>
                    <h4 style={sectionTitleStyles}>Products to Inspect</h4>
                    <p><strong>Quantity:</strong> {reqData.quanty}</p>
                    <p><strong>Flooring Category:</strong> {reqData.category}</p>
                  </div>

                  <hr style={dividerStyles} />

                  {/* Concerns */}
                  <div style={sectionStyles}>
                    <h4 style={sectionTitleStyles}>Concerns</h4>
                    <p>{reqData.concerns}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </Modal>

    </div>
  );
}

export default InspectionRequestData;
