import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, message, Card, Select, Row, Col, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/Layout/PageLayout';
import InspectorForm from '../../components/Inspectors/InspectorForm.js';
import inspectorService from '../../services/inspectorService';

const InspectorsCRUD = () => {
    const [inspectors, setInspectors] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingInspector, setEditingInspector] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [form] = Form.useForm();
    const validStatuses = [
        { key: 'active', label: 'Active' },
        { key: 'inactive', label: 'Inactive' },
    ];

    useEffect(() => {
        fetchInspectors();
        setStatuses(validStatuses);
        // eslint-disable-next-line
    }, []);
    const fetchInspectors = async () => {
        try {
            setLoading(true);
            const data = await inspectorService.getAllInspectors();
            setInspectors(data);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleModalOpen = (inspector = null) => {
        setEditingInspector(inspector);
        if (inspector && inspector.name) {
            form.setFieldsValue({
                name: inspector.name,
                email: inspector.email,
                location: inspector.location,
                expertise: inspector.expertise
            });
            setEditMode(true);
        } else {
            form.resetFields();
        }
        setIsModalVisible(true);
    };
    const handleModalSubmit = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);

            if (editingInspector) {
                await inspectorService.updateInspector(editingInspector.id, values);
                message.success('Inspector updated successfully.');
            } else {
                await inspectorService.createInspector(values);
                message.success('Inspector added successfully. Login credentials sent via email.');
            }

            setIsModalVisible(false);
            form.resetFields();
            fetchInspectors();
            setEditingInspector(null);
            setEditMode(false);
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (id, status) => {
        try {
            setLoading(true);
            await inspectorService.updateInspectorStatus(id, status);
            message.success('Status updated successfully');
            fetchInspectors();
        } catch (error) {
            message.success('Error updating status');
        } finally {
            setLoading(false);
        }
    };
    const handleModalCancel = () => {
        setIsModalVisible(false);
        setEditingInspector(null);
        setEditMode(false);
    };

    const columns = [
        { title: 'Name', dataIndex: 'name', key: 'name' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Location', dataIndex: 'location', key: 'location' },
        { title: 'Expertise', dataIndex: 'expertise', key: 'expertise' },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => (
                <StatusCell
                    status={status}
                    record={record}
                    statuses={statuses}
                    onStatusChange={handleStatusChange}
                />
            ),
        },
        {
            title: 'Registered',
            dataIndex: 'date_registered',
            key: 'date_registered',
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <ActionButtons
                    record={record}
                    onEdit={() => handleModalOpen(record)}
                />
            ),
        },
    ];

    return (
        <PageLayout>
            <Card className="fsmanagerPanelCard" style={{ borderRadius: "8px" }}>
                <Row gutter={[16, 16]} justify="space-between">
                    <Col xs={24} md={12}>
                        <div className="headerTitle">
                            <p className="tableHeaderText">Manage Inspectors</p>
                            <p className="claimCount">Total Inspectors: {inspectors.length}</p>
                        </div>
                    </Col>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 16 }}>
                            <Button
                            className='addInspector-btn'
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={handleModalOpen}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                Add Inspector
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Divider />
                <div className='table-responsive'>
                <Table
                    columns={columns}
                    dataSource={inspectors}
                    className="w-full"
                    rowKey="id"
                    loading={loading}
                />
                </div>
                <Modal
                    open={isModalVisible}
                    onOpenChange={setIsModalVisible}
                    onCancel={handleModalCancel}
                    title={<span className="text-xl font-semibold">{editMode ? 'Edit Inspector' : 'Add Inspector'}</span>}
                    centered
                    footer={null}
                    bodyStyle={{ padding: '24px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
                >
                    <div className="p-4 bg-white rounded-lg">
                        <InspectorForm
                            form={form}
                            editMode={editMode}
                            inspectorData={editingInspector}
                        />

                        <div className="flex justify-end gap-2 mt-6">

                            <Button type="primary" onClick={handleModalSubmit} className="hover:bg-blue-600 transition">
                                {editMode ? 'Update' : 'Create'}
                            </Button>
                        </div>
                    </div>
                </Modal>

            </Card>
        </PageLayout>
    );
};

const StatusCell = ({ status, record, statuses, onStatusChange }) => (
    <div className="flex items-center gap-2 fsmanager-status-date" >
        <Select
            value={status}
            onChange={(value) => onStatusChange(record.id, value)}
            className="w-auto min-w-[120px] max-w-[200px] flex-grow" // Allow flexibility
        >
            {statuses.map((s) => (
                <Select.Option key={s.key} value={s.key}>
                    {s.label}
                </Select.Option>
            ))}
        </Select>
        {record.status_changed_at && (
            <span className="text-sm text-gray-500">
                Updated: {new Date(record.status_changed_at).toLocaleDateString()}
            </span>
        )}
    </div>
);


const ActionButtons = ({ record, onEdit }) => (
    <div className="flex gap-2">
        <Button
            variant="outline"
            onClick={onEdit}
        >
            Edit
        </Button>
    </div>
);
export default InspectorsCRUD;