import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Divider,
  Upload,
  message,
  Image,
  Tooltip,
  Spin,
  notification
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  LinkOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Tabs } from "antd";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import axios from "axios";
import Repeater from "../components/EditProfile/Repeater";
import Colorpick from "../components/EditProfile/Colorpick";
import * as InspectorProfileService from "../services/InspectorProfile";
import SpinWaitCustom from '../components/SpinnerWait';
import { MEDIA_UPLOAD_URL } from "../Helper";
import { useDispatch } from "react-redux";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  link,
} from "suneditor/src/plugins";
import * as UserDataService from '../services/GetUserData';
import * as Actions from '../store/actions/user';

function InspectorProfile() {
  const [loading, setLoading] = useState(false);
  const [isPrivateAddress, setIsPrivateAddress] = useState(false);
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [profilePicId, setProfilePicId] = useState("");
  const [loadinglogo, setLoadinglogo] = useState(false);
  const [loadingBgCheck, setLoadingBgCheck] = useState(false);
  const [loadingsign, setLoadingsign] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [nwfaCertified, setNwfaCertified] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [signUrl, setSignUrl] = useState("");
  const [signID, setSignID] = useState("");
  const [LogoimageUrl, setLogoImageUrl] = useState("");
  const [BgCheckimageUrl, setBgCheckImageUrl] = useState("");
  const [bgCheckImageEntension, setBgCheckImageEntension] = useState();
  const [BgCheckimageID, setBgCheckimageID] = useState("");
  const [footerLogo, setFooterLogo] = useState([]);
  const [inspectorTypes, setInspectorTypes] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const profile_id = queryParams.get("inspector_id");
  const [aboutMe, setAboutMe] = useState("");
  const [privateNotes, setPrivateNotes] = useState("");
  const [invoiceNotes, setInvoiceNotes] = useState("");
  const [formData, setFormData] = useState({});
  const [selectedInspectorTypes, setSelectedInspectorTypes] = useState([]);
  const [pdfColor, setPdfColor] = useState("");
  const [logoImageId, setLogoImageId] = useState("");
  const [items, setItems] = useState([{ file: null,file_id: null,loading: false,certification_authority: '', date: new Date() }]);


  var dispatch = useDispatch();
  const getUserDetails = () => {
    dispatch(UserDataService.getUserData())
      .then((res) => {
        dispatch(Actions.set_user_auth(res));
        if (res?.expired === true || res?.remaining_inspections <= 0) {
          window.location.href = 'https://www.floordetective.com/plan-expired';
        }
        if (
          res?.membersdhip_level === '7' ||
          res?.user_role === 'um_retailer' ||
          res?.user_role === 'um_dealer'
        ) {
          window.location.href = 'https://www.floordetective.com/';
        }
        if (res?.companycam_token){
          localStorage.setItem('companycam_token', res.companycam_token);
        }
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      notification.error({ message: 'Error', description: errorMessage });
      });
  };
  const fetchProfileData = (params = {}) => {
    getUserDetails();
    setLoadings(true);
    setLoading(true);
    dispatch(InspectorProfileService.getInspectorProfileData(profile_id))
      .then((res) => {
        setFormData(res);
        setInspectorTypes(res.all_inspector_types);
        setSelectedInspectorTypes(res.inspector_type)
        setFooterLogo(res.all_footer_logos);
        setImageUrl(res.profile_pic);
        setProfilePicId(res.profile_pic_id);
        setLogoImageId(res.logo);
        setSignID(res.sign_for_report);
        setBgCheckimageID(res.background_check);
        setBgCheckImageUrl(res.BgCheckimageUrl);
        let fileExtension = res.BgCheckimageUrl.split('.');
        let lastElement = fileExtension[fileExtension.length - 1];
        setBgCheckImageEntension(lastElement);
        setSignUrl(res.signurl);
        setLogoImageUrl(res.logo_url);
        setAboutMe(res.about_me);
        setPrivateNotes(res.inspector_private_notes);
        setInvoiceNotes(res.invoice_extra_notes);
        setIsPrivateAddress(res.make_address_private);
        setPdfColor(res.pdf_color);
        if (res.certificates && res.certificates !== ''){
          setItems(res.certificates);
        }
        setNwfaCertified(res.nwfa_certified);
        setLoading(false);
        setLoadings(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
        setLoadings(false);
      });
  };
  function renderImage() {
    if (bgCheckImageEntension === 'pdf') {
      return (
        <img
          src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/13062734/PDF_file_icon.svg_65f17f92c093265f17f92c09d7.png"
          alt="PDF icon"
          style={{ width: "100%", height: "70px", objectFit: "contain" }}
        />
      );
    } else if (bgCheckImageEntension === 'docx' || bgCheckImageEntension === 'doc') {
      return (
        <img
          src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/03/13062726/824298865f17f8c0ccdf65f17f8c0cd69.png"
          alt="DOCX/DOC icon"
          style={{ width: "100%", height: "70px", objectFit: "contain" }}
        />
      );
    } else if (bgCheckImageEntension === "jpg" || bgCheckImageEntension === "png" || bgCheckImageEntension === "jpeg") {
      return (
        <img
          src={BgCheckimageUrl}
          alt="Uploaded-img"
          style={{ width: "100%" }}
        />
      );
    } else {
      return (
        // <UploadOutlined />
        BgCheckuploadButton
      );
    }
  }
  const onFinish = (values) => {
    setLoadings(true);
    message.loading('Action in progress..');
    values.profile_id = profile_id;
    values.profile_pic_id = profilePicId;
    values.sign_for_report = signID;
    values.inspectorTypes = inspectorTypes;
    values.about_me = aboutMe;
    values.invoice_extra_notes = invoiceNotes;
    values.inspector_private_notes = privateNotes;
    values.pdf_color = pdfColor;
    values.company_logo = logoImageId;
    values.background_check = BgCheckimageID;
    values.certificates = items;
    // values.signUrl = signUrl;
    dispatch(InspectorProfileService.updateInspectorProfile(values))
      .then((res) => {
        setFormData(res);
        getUserDetails();
        message.success("Profile updated successfully!");
        setLoadings(false);
      })
      .catch((err) => {
        message.error("Unable to update Profile");
        setLoadings(false);
      });
  };
  useEffect(() => {
    fetchProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeInspectorTypes = (checkedValues) => {
    setSelectedInspectorTypes(checkedValues);
  };


  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleTabChange = (key) => {
    console.log(`Tab changed to: ${key}`);
  };

  const onChangeAboutMe = (editorData) => {
    setAboutMe(editorData);
  };
  const onChangePrivateNotes = (editorData) => {
    setPrivateNotes(editorData);
  };
  const onChangeInvoiceNotes = (editorData) => {
    setInvoiceNotes(editorData);
  };
  const SunEditorOpts = {
    showPathLabel: false,
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule", "lineHeight"],
      ["outdent", "indent"],
      ["list"],
      ["table", "link"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };


  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  const handleChangeSignature = (info) => {
    if (info.file.status === "uploading") {
      setLoadingsign(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadingsign(false);
        ///setImageUrl(url);
        setSignUrl(url);
        setSignID(info.file.response);
        //props.values.signurl = url;
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUploadSign = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Sign
      </div>
    </div>
  );

  const normFileLogo = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleChangeImageLogo = (info) => {
    if (info.file.status === "uploading") {
      setLoadinglogo(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoadinglogo(false);
        setLogoImageUrl(url);
        setLogoImageId(info.file.response);
        //props.values.signurl = url;
      });
    }
  };

  const beforeUploadLogo = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };
  const LogouploadButton = (
    <div>
      {loadinglogo ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload logo
      </div>
    </div>
  );

  const normFileBgCheck = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleChangeImageBgCheck = (info) => {
    if (info.file.status === "uploading") {
      setLoadingBgCheck(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        // setLoadinglogo(false);
        setLoadingBgCheck(false);
        setBgCheckImageUrl(url);
        let fileExtension = info.file.name.split('.');

        let lastElement = fileExtension[fileExtension.length - 1];
        setBgCheckImageEntension(lastElement);
        setBgCheckimageID(info.file.response);
      });
    }
  };
  const beforeUploadBgCheck = (file) => {
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      message.error("You can only upload JPG, PNG, PDF, or DOCX files!");
      return false;
    }

    const isLt20M = file.size / 1024 / 1024 < 20;

    if (!isLt20M) {
      message.error("Image must smaller than 20MB!");
    }

    return allowedFileTypes.includes(file.type) && isLt20M;
  };
  const BgCheckuploadButton = (
    <div>
      {loadingBgCheck ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Add File
      </div>
    </div>
  );
  console.log("yyy", formData);
  const handleProfileImageChange = (info) => {
    if (info.file.status === "uploading") {
      setProfilePicLoading(true);
      return;
    }

    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setProfilePicLoading(false);
        setImageUrl(url);
        setProfilePicId(info.file.response);
        console.warn('profile image', info.file);
        //props.values.signurl = url;
      });
    }
  };
  const ProfileuploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Profile
      </div>
    </div>
  );
  // Define your custom request function for the API upload
  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      console.info("UPLOADED");
      onSuccess(res.data.id);
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
      console.warn(error);
    }
  };


  const handlePrivateCheckboxChange = () => {
    setIsPrivateAddress(!isPrivateAddress);
  };
  return (
    <div className="container-xxl inspector-profile-container">
      <Row justify="center">
        <Col xs={24} md={20}>
          {Object.keys(formData).length === 0 ? (
            // Loading indicator or placeholder while data is being fetched
            <SpinWaitCustom />
          ) : (
            <Form
              name="editProfileForm"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={formData}
            >
              <div className="avatar-wrapper">
                <Spin spinning={profilePicLoading}>
                  <Upload className="profile-image-upload"
                    customRequest={uploadImage}
                    showUploadList={false} // Hide the default upload list
                    onChange={handleProfileImageChange}
                    beforeUpload={(file) => {
                      // Implement your file validation logic here
                      // Return false to prevent upload, or true to allow upload
                      console.log(file);
                      return true;
                    }}
                  > {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      className="profileImage-Box"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    ProfileuploadButton
                  )}
                    <Tooltip title="Upload Profile Picture">
                      <div className="upload-button">
                        <CloudUploadOutlined />
                      </div>
                    </Tooltip>
                  </Upload>
                </Spin>
              </div>

              {/* Name input */}

              <Row gutter={16}>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Organization name"
                    name="organization_name"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="organization"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Full name"
                    name="insp_fullname"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input prefix={<UserOutlined />} placeholder="Full name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Address"
                    name="address"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      { required: true, message: "Please input your address!" },
                    ]}
                  >
                    <Input
                      prefix={<EnvironmentOutlined />}
                      placeholder="Address"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      { required: true, message: "Please input your email!" },
                    ]}
                  >
                    <Input prefix={<MailOutlined />} placeholder="Email" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Phone Number"
                    name="phone_number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      prefix={<PhoneOutlined />}
                      placeholder="Phone Number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Website"
                    name="website"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input prefix={<LinkOutlined />} placeholder="Website" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Company phone number"
                    name="company_phone_number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      prefix={<PhoneOutlined />}
                      placeholder="Company phone number"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Location Title"
                    name="location_title"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      { required: true, message: "Please input Location title!" },
                    ]}
                  >
                    <Input
                      prefix={<EnvironmentOutlined />}
                      placeholder="Location Title"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    label="Inspector Certification/CP #"
                    name="inspector_certificationcp"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Input
                      prefix={<UserOutlined />}
                      placeholder="Inspector Certification/CP"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} md={24} xl={24}>
                  <h6 className="hide-and-private">Hide & Private Fields</h6>
                  <Divider></Divider>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    name="make_address_private"
                    valuePropName="checked"
                    onChange={handlePrivateCheckboxChange}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Checkbox>Make address private</Checkbox>
                  </Form.Item>
                  {isPrivateAddress && (
                    <Form.Item
                      label="Zipcode"
                      name="zip_code"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        prefix={<EnvironmentOutlined />}
                        placeholder="Zipcode"
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    name="address_hide_report"
                    valuePropName="checked"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Checkbox>Address hide for invoice and report</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} xl={8}>
                  <Form.Item
                    name="hide_my_profile"
                    valuePropName="checked"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Checkbox>
                      Hide my profile in the inspector listing.
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={16}> */}
              <div className="inspector-types">
                <h6>Inspector Type</h6>
                <Divider></Divider>
                <Form.Item name="inspector_type">
                  <Checkbox.Group style={{ width: "100%" }} onChange={onChangeInspectorTypes}>
                    <Row gutter={16}>
                      {inspectorTypes.map((type) => (
                        type.parent === 0 && (
                          <Col xs={24} md={8} xl={8} key={type.term_id}>
                            <Checkbox value={type.term_id}>{type.name}</Checkbox>
                          </Col>
                        )
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
                {selectedInspectorTypes && selectedInspectorTypes.includes(84) && (
                  <Form.Item name="second_group">
                    <Checkbox.Group style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", border: "1px inset #ccc" }}>
                      <Row gutter={16}>
                        {inspectorTypes
                          .filter(type => type.parent === 84)
                          .map((type) => (
                            <Col xs={24} md={8} xl={8} key={type.term_id}>
                              <Checkbox value={type.term_id}>{type.name}</Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                )}
              </div>
              <Row gutter={16}></Row>

              <Tabs
                className="profile-tab-section"
                defaultActiveKey="1"
                onChange={handleTabChange}
              >
                <Tabs tab="About" key="1">
                  <span className="tab-content-title">About Me</span>
                  <div className="text-editor-wrapper">
                    <SunEditor
                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                      setOptions={SunEditorOpts}
                      setContents={aboutMe}
                      onChange={onChangeAboutMe}
                    />
                  </div>
                  <span className="tab-content-title">Certifications logo</span>
                  <Row className="my-2 footerRow" gutter={6}>
                    <Form.Item name="footerlogos">
                      <Checkbox.Group>
                        {footerLogo.map((logo) => (
                          <Col className="col-auto h-100">
                            <div className="logoDiv">
                              <Checkbox value={logo.logo_id}>
                                <label htmlFor={logo.logo_id}>
                                  <Image
                                    src={logo.logo_url}
                                    className="img-fluid"
                                    alt="logos"
                                    preview={false}
                                  />
                                </label>
                              </Checkbox>
                            </div>
                          </Col>
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </Row>
                </Tabs>
                <Tabs tab="Private Notes" key="2">
                  <span className="tab-content-title">Private Notes</span>
                  <div className="text-editor-wrapper">
                    <SunEditor
                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                      setOptions={SunEditorOpts}
                      setContents={privateNotes}
                      onChange={onChangePrivateNotes}
                    />
                  </div>
                </Tabs>
                <Tabs tab="Report Writer Settings" key="3">
                  {/* <span className='tab-content-title'>Report Writer Settings</span> */}
                  <Row gutter={16}>
                    <Col xs={24} md={6} xl={6}>
                      <div className="pdf-color-settings">
                        <h6 className="pdf-color-label">Company Logo</h6>
                        <Divider></Divider>
                        <div className="image-wrapper">
                          <Form.Item
                            name="logoUpload"
                            valuePropName="fileList"
                            getValueFromEvent={normFileLogo}
                          >
                                   <Spin className="spin-company-logo" spinning={loadinglogo}>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              customRequest={uploadImage}
                              beforeUpload={beforeUploadLogo}
                              onChange={handleChangeImageLogo}
                              maxCount={1}
                              loading={loadinglogo}
                            >
                              {LogoimageUrl ? (
                                <img
                                  src={LogoimageUrl}
                                  alt="avatar"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                LogouploadButton
                              )}
                            </Upload>
                            </Spin>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>

                    <Col xs={24} md={6} xl={6}>
                      <div className="pdf-color-settings">
                        <h6 className="pdf-color-label">Background check</h6>
                        <Divider></Divider>
                        <div className="image-wrapper">
                          <Form.Item
                            name="logoUpload"
                            valuePropName="fileList"
                            getValueFromEvent={normFileBgCheck}
                          >
                            <Spin className="spin-loadingBg" spinning={loadingBgCheck}>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              customRequest={uploadImage}
                              beforeUpload={beforeUploadBgCheck}
                              onChange={handleChangeImageBgCheck}
                            >
                              {renderImage()}
                            </Upload>
                            </Spin>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={16}>
                    <Col xs={24} md={6} xl={6}>
                      <div className="pdf-color-settings">
                        <h6 className="pdf-color-label">Signature</h6>
                        <Divider></Divider>
                        <div className="image-wrapper">
                          <Form.Item
                            name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                          >
                            <Spin className="spin-loadingsign" spinning={loadingsign}>
                            <Upload
                              name="avatar"
                              listType="picture-card"
                              className="avatar-uploader signature-upload"
                              showUploadList={false}
                              customRequest={uploadImage}
                              beforeUpload={beforeUploadSign}
                              onChange={handleChangeSignature}
                            >
                              {console.info("signUrl",signUrl)}
                              {signUrl ? (
                                <img
                                  src={signUrl}
                                  alt="avatar"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                            </Spin>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <div className="pdf-color-settings">
                        <h6 className="pdf-color-label">Pdf Color Setting</h6>
                        <Divider></Divider>
                        <span> Select Color</span>
                        <Colorpick color={pdfColor} setPdfColor={setPdfColor} />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} className="invoice-notes-row">
                    <Col xs={24} md={24} xl={24}>
                      <span className="tab-content-title">Invoice Notes</span>
                      <div className="text-editor-wrapper">
                        <SunEditor
                          setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                          setOptions={SunEditorOpts}
                          setContents={invoiceNotes}
                          onChange={onChangeInvoiceNotes}
                        />
                      </div>
                    </Col>
                  </Row>
                </Tabs>
                <Tabs tab="Certification & Cv" key="6">
                  <span className="tab-content-title">Certification & Cv</span>
                  <Repeater items={items} setItems={setItems} nwfaCertified={nwfaCertified}/>
                </Tabs>
              </Tabs>
              <Form.Item className="buttonOuter mt-4 text-center">
                <Button type="primary" htmlType="submit" loading={loadings}>
                  Update Profile
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default InspectorProfile;
