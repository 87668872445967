import React, { useState, useEffect, useCallback } from "react";
import { Table, Row, Col, Card, Divider, Input, Skeleton, Empty, Form, Button, Modal, message, Select } from "antd";
import { EyeFilled, SearchOutlined, FormOutlined, FileOutlined, MailOutlined } from "@ant-design/icons";
import { IconButton } from "../../components/Buttons/buttons";
import * as GetFsinspectorPanelData from "../../services/fsinspectorPanel";
import * as InspectionServices from '../../services/InspectionReports';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from 'lodash/debounce';
import Swal from 'sweetalert2';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const FsinspectorPanelDataTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [inspections, setInspections] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [statusValues, setStatusValues] = useState({});
  const [statusModal, setStatusModal] = useState(false);
  const [clientDropdown, setClientDropdown] = useState([]);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchFsinspectorPanelData = useCallback(() => {
    setLoading(true);
    dispatch(GetFsinspectorPanelData.getInspectionFsinspectorPanelData())
      .then((res) => {
        setInspections(res);
        setFilteredInspections(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching FS Inspector data:", err);
        setLoading(false);
      });
  }, [dispatch]);

  const getCommentData = useCallback((req_id) => {
    setLoading(true);
    dispatch(InspectionServices.getComments(req_id))
      .then((res) => {
        setCommentData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching comments:', err);
        setLoading(false);
      });
  }, [dispatch]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchedText(value);
    }, 300),
    []
  );

  useEffect(() => {
    fetchFsinspectorPanelData();
    return () => {
      debouncedSearch.cancel();
    };
  }, [fetchFsinspectorPanelData, debouncedSearch]);

  useEffect(() => {
    if (searchedText) {
      const filteredData = inspections.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(searchedText.toLowerCase())
        )
      );
      setFilteredInspections(filteredData);
    } else {
      const filtered = inspections.filter((inspections) => {
        return (
          inspections.status_hidden !== 'Cancelled' &&
          inspections.status_hidden !== 'Delete' &&
          inspections.status_hidden !== 'RFQ_deleted'
        );
      });
      setFilteredInspections(filtered);
    }
  }, [searchedText, inspections]);

  const handleStatusChange = (value) => {
    form.setFieldsValue({ status: value });
    if (value === 'Delete') {
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure you want to delete permanently?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle delete confirmation
        }
      });
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    values.pId = statusValues.req_id;
    values.notifyClient = form.getFieldValue('notifyClient') || false;
    values.inspection_date_status = values.inspection_date_status
      ? moment(values.inspection_date_status).format('MM-DD-YYYY')
      : '';

    dispatch(InspectionServices.updateStatus(values))
      .then(() => {
        message.success('Status updated!');
        form.resetFields();
        setStatusModal(false);
        fetchFsinspectorPanelData();
      })
      .catch((err) => {
        console.error('Error updating status:', err);
        message.error('Failed to update status');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openStatusModal = (statusValues) => {
    setStatusValues(statusValues);
    getCommentData(statusValues.req_id);
    form.setFieldsValue({
      client: statusValues.client_name !== '-' ? statusValues.client_name : undefined,
      status: statusValues.status,
    });
    setStatusModal(true);
  };

  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (_, __, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Claim #",
      dataIndex: "claim_number",
      key: "claim_number",
      sorter: (a, b) => a.claim_number.localeCompare(b.claim_number),
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      sorter: (a, b) => moment(a.request_date).diff(moment(b.request_date)),
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    },
    {
      title: "Status",
      dataIndex: "status_hidden",
      key: "status_hidden",
      render: (text, action) => (
        <>
          <Button
            color="#ffffff"
            className="statusTags fsinspectorPanel-status-btn"
            onClick={() => openStatusModal(action.status)}
          >
            {action.status_hidden}
          </Button>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <IconButton
            icon={<EyeFilled />}
            type="primary"
            tooltip="View"
            text="View"
            handleClick={() => navigate(`/single-request/?req_id=${record.action.req_id}`)}
          />
          {record.action.report_generated === 'YES' && record.action.reportId ? (
            <IconButton
              icon={<FormOutlined />}
              type="primary"
              tooltip="Edit Report"
              text="Report"
              handleClick={() => navigate(`/field-service-resolution-form/?report_id=${record.action.reportId}`)}
            />
          ) : (
            <IconButton
              icon={<FileOutlined />}
              type="primary"
              tooltip="Create Report"
              text="Report"
              handleClick={() => navigate(`/field-service-resolution-form/?req_id=${record.action.req_id}`)}
            />
          )}
          {record.action.report_generated === 'YES' && record.action.reportId ? (
            <IconButton
              icon={<MailOutlined />}
              type="primary"
              tooltip="Send Email"
              text="Email"
              handleClick={() =>
                navigate('/send-email/?req_id=' + record.action.req_id)
              }
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Card className="fsinspectorPanelCard" style={{ borderRadius: "8px" }}>
        <Row gutter={[16, 16]} justify="space-between">
          <Col xs={24} md={12}>
            <div className="headerTitle">
              <p className="tableHeaderText">FS Inspector Claim Listing</p>
              <p className="claimCount totalClaims">Total Claims: {filteredInspections.length}</p>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <Input
              className="searchInput fsinspectorPanel-input"
              prefix={<SearchOutlined />}
              allowClear
              placeholder="Search by Customer, Claim #, FS Inspector..."
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </Col>
        </Row>

        <Divider />

        <Table
          bordered
          className="clientsDataTable"
          columns={columns}
          dataSource={filteredInspections}
          loading={loading}
          locale={{
            emptyText: loading ? <Skeleton active /> : <Empty />,
          }}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            },
            position: ["bottomCenter"],
          }}
          scroll={{ x: 1000 }}
        />
      </Card>

      <Modal
        title="Change Status"
        centered
        open={statusModal}
        className="inspectionCategoryModal industryModal"
        width={720}
        onOk={form.submit}
        footer={[
          <Button
            key="submit"
            htmlType="submit"
            className="submitBtn"
            onClick={form.submit}
            loading={loading}
          >
            Save Changes
          </Button>,
          <Button key="cancel" className="cancelBtn" onClick={() => setStatusModal(false)}>
            Cancel
          </Button>,
        ]}
        onCancel={() => setStatusModal(false)}
      >
        <Form
          form={form}
          name="statusForm"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item label="Client" name="client">
                <Select
                  disabled={statusValues.req_type === 'Flooring Manufacturer' || statusValues.client_name !== '-'}
                >
                  {clientDropdown.map((option) => (
                    <Option value={option.id} key={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label="Status" name="status">
                <Select onChange={handleStatusChange}>
                  <Option value="Pending">Pending</Option>
                  <Option value="In Progress">In Progress</Option>
                  <Option value="Completed">Completed</Option>
                  <Option value="Draft">Draft</Option>
                  <Option value="Delete">Delete</Option>
                  <Option value="Cancelled">Cancelled</Option>
                  <Option value="More info requested">More info requested</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item label="Comments" name="comments">
                <TextArea
                  placeholder="Comments"
                  className="customTextarea formControl"
                  rows={4}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="commentsSection">
          <div className="notes-show-section">
            <ul className="notes-list">
              {loading ? (
                <Skeleton active />
              ) : (
                commentData.data?.map((comment, index) => (
                  <li className="notes-items" key={index}>
                    <div className="note-content">
                      <p dangerouslySetInnerHTML={{ __html: comment.comment_content }}></p>
                    </div>
                    <p className="notes-meta">
                      {comment.comment_date}
                      <span className="mx-2">{comment.comment_author}</span>
                    </p>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FsinspectorPanelDataTable;