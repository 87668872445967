import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, message, Typography, Image, Tooltip } from 'antd';
import { HomeOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';

import CustomerResolutionForm from './CustomerResolutionForm'; // This form can now handle both create and update modes
import CustomerResolutionPreview from './CustomerResolutionPreview';
import mohLogo from '../../../assets/images/Mohawk_Industries_logo.png';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { saveFsInspectionReport, getFsInspectionReport, getFsInspectionRequest, generatePdfInspectionReport } from '../../../services/FsReportApis';

const CustomerResolutionReportContainer = () => {
    const [currentView, setCurrentView] = useState('form'); // 'form', 'preview'
    const [reportData, setReportData] = useState({}); // Initialize with dummy data
    const { Title, Text } = Typography;
    const [loading, setLoading] = useState(false);
    const DateFormat = 'MM-DD-YYYY';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const reportId = queryParams.get('report_id');
    const requestId = queryParams.get('req_id');

    const handleFormSubmit = (formData) => {

        console.info(formData)
        setLoading(true);
        const updatedFormData = {
            ...formData,
            ...(reportId && { reportId: reportId }),
            ...(requestId && { reqId: requestId }),
        };
        dispatch(saveFsInspectionReport(updatedFormData))
            .then((response) => {
                setLoading(false);
                setReportData(formData);
                setCurrentView('preview');
                fetchData();
                if (response.reportId) {
                    navigate(`/field-service-resolution-form/?report_id=${response.reportId}`)
                }
                message.success('Report submitted successfully!');
                console.log("Report saved successfully", response);
            })
            .catch((err) => {
                setLoading(false);
                console.error("Failed to save report", err)
            });

    };

    const handleGeneratePdf = () => {
        setLoading(true);
        dispatch(generatePdfInspectionReport(reportId))
            .then((res) => {
                setLoading(false);
                window.open(res.pdf_url);
            })
            .catch((error) => {
                setLoading(false);
                console.error("Failed to generate PDF report", error);
                message.error("Failed to generate PDF report. Please try again.");
            });
    };
    // Fetch report by ID
    useEffect(() => {

        if (reportId || requestId) {
            fetchData();
            if (reportId) {
                setCurrentView('preview');
            }
        }
    }, [reportId, requestId, dispatch]);
    const fetchData = async () => {
        try {
            if (reportId) {
                const data = await dispatch(getFsInspectionReport(reportId));
                setReportData(data);
            } else if (requestId) {
                const data = await dispatch(getFsInspectionRequest(requestId));
                setReportData(data);
            }
        } catch (err) {
            console.error("Failed to fetch report or request", err);
        }
    };
    const ReportHeader = () => (
        <div style={{ textAlign: 'center', marginBottom: '24px' }}>
            <Image
                src={mohLogo}
                alt="Mohawk Logo"
                preview={false}
                className='mohawak-logo'
                style={{ maxHeight: '100px', marginBottom: '16px' }}
            />
            <Title level={2}>Inspection Report</Title>
            <Text>Address: 160 S. Industrial Blvd. Calhoun, GA 30701</Text>
            <div style={{ margin: '16px 0' }}>
                <Text>Inspection Date: {reportData.requestDate ? moment(reportData.requestDate).format(DateFormat) : 'N/A'}</Text>
            </div>
            <div>
                <Text>Inspector Name: {reportData.inspectorName ? reportData.inspectorName : 'N/A'}</Text>
            </div>
        </div>
    );
    const navigateToHome = () => {
        // Logic to navigate to the homepage, e.g., using react-router's useHistory or window.location
        window.location.href = '/'; // Update if using react-router's navigation
    };

    return (
        <div className='main-content-section' style={styles.container}>
            <div className='container py-0  my-5'>
                <div className='row'>
                    <Card className='tablet-padding-0' style={styles.card}>
                        <Button
                            icon={<HomeOutlined />}
                            onClick={navigateToHome}
                            className='home-mohawak-btn'
                            style={{ ...styles.button, marginBottom: '16px' }}
                        >
                            Home
                        </Button>
                        <ReportHeader />
                        {currentView === 'form' && (
                            <CustomerResolutionForm
                                onFinish={handleFormSubmit}
                                loading={loading}
                                setLoading={setLoading}
                                initialData={reportData} // Pass reportData for update mode
                            />
                        )}
                        {currentView === 'preview' && (
                            <>
                                <CustomerResolutionPreview formData={reportData} />
                                <div className='circle-button-div'>
                                    <Tooltip title="Edit Report" placement="top">
                                        <Button
                                            type='primary'
                                            shape="circle"
                                            icon={<EditOutlined className="svg-icon" />}
                                            onClick={() => setCurrentView('form')}
                                            className="circle-custom-button "
                                            style={{ marginRight: '10px' }}
                                        />
                                    </Tooltip>
                                    <Tooltip title="Generate PDF" placement="top">
                                        <Button
                                            type='primary'
                                            shape="circle"
                                            loading={loading}
                                            icon={<FilePdfOutlined className="svg-icon" />}
                                            onClick={() => handleGeneratePdf()}
                                            className="circle-custom-button "

                                        />
                                    </Tooltip>
                                </div>
                            </>
                        )}
                    </Card>
                </div>
            </div>
        </div>

    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f0f2f5',
    },
    card: {
        // width: '60%',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        borderRadius: '12px',
        backgroundColor: '#fff',
    },
    button: {
        marginTop: '15px',
        borderRadius: '6px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
};

export default CustomerResolutionReportContainer;
