import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Row,
  Col,
  Card,
  Select,
  DatePicker,
  Modal,
  Form,
  Empty,
  message,
  Checkbox,
  Divider,
  Button, Skeleton
} from "antd";
import { IconButton } from "../components/Buttons/buttons";
import {
  BookFilled,
  DownOutlined,
  EyeFilled,
  MailOutlined,
  FileOutlined,
  SearchOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as ManufactureRequest from "../services/ManufactureRequest";
import * as InspectionServices from "../services/InspectionReports";
import * as getClientdropService from "../services/getClientdrop";
import { useDispatch } from "react-redux";
import * as PdfServices from "../services/GetPdf";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

function AllManufacturerClaimTable(props) {
  var dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [filterdinspections, setFilterdInspections] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [claimAnalystDropdown, setclaimAnalystDropdown] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusValues, setStatusValues] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [checkedins, setCheckedins] = useState(true);
  const [checkedass, setCheckedass] = useState(true);
  const [commentData, setCommentData] = useState([]);
  const [remainingInsoections, setRemainingInspections] = useState([]);
  let navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData();
    fetchClaimAnalystData();
    fetchRemaininginspections();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeKey]);

  const onChanges = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  const onChangeStartDate = (date, dateString) => {
    setFilterStartDate(moment(dateString).format("MM-DD-YYYY"));
    let EndDate = filterEndDate ? filterEndDate : moment().format("MM-DD-YYYY");
    FilterAccordingDate(moment(dateString).format("MM-DD-YYYY"), EndDate);
  };

  const handleCancel = () => {
    setStatusModal(false);
  };

  const onChangeEndDate = (date, dateString) => {
    console.log(date, dateString);
    console.warn(moment(dateString).format("MM-DD-YYYY"));
    setFilterEndDate(moment(dateString).format("MM-DD-YYYY"));
    let startDate = filterStartDate
      ? filterStartDate
      : moment().format("MM-DD-YYYY");
    FilterAccordingDate(startDate, moment(dateString).format("MM-DD-YYYY"));
  };

  const FilterAccordingDate = (startdate, enddate) => {
    let datefilter = [];
    inspections.forEach((inspection) => {
      if (
        moment(inspection.date).isSameOrAfter(moment(startdate)) &&
        moment(inspection.date).isSameOrBefore(moment(enddate))
      ) {
        datefilter.push(inspection);
      }
    });
    setFilterdInspections(datefilter);
  };

  const handleChange = (newValue) => {
    let res = inspections;
    if (newValue === "complete") {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === "complete";
      });
      setFilterdInspections(filtered);
    } else if (newValue === "pending") {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === "pending";
      });
      setFilterdInspections(filtered);
    } else if (newValue === "draft") {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === "draft";
      });
      setFilterdInspections(filtered);
    } else if (newValue === "cancelled") {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === "cancelled";
      });
      setFilterdInspections(filtered);
    } else if (newValue === "archived") {
      const filtered = res.filter((inspections) => {
        return inspections.status_hidden === "archived";
      });
      setFilterdInspections(filtered);
    } else {
      setFilterdInspections(inspections);
    }
  };

  const onFinish = (values) => {
    enterLoading(0);
    values.pId = statusValues.req_id;
    values.client_name = statusValues.client_name;
    values.client_email = statusValues.client_email;
    values.self_name = statusValues.self_name;
    values.self_email = statusValues.self_email;
    values.inspector_id = statusValues.inspector_id;
    values.notify_ins = checkedins === true ? 'yes' : '';
    values.notify_assignee = checkedass === true ? "yes" : "";
    console.warn("status form on submit", values);
    dispatch(InspectionServices.updateStatusClaim(values))
      .then((res) => {
        fetchData();
        message.success("Status updated!");
        setStatusModal(false);
        console.warn("result from update status api", res);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        const errorMessage = err?.response?.data?.message || "Failed to update status. Please try again later.";

        message.error(errorMessage);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const openStatusModal = (statusValues) => {
    setStatusValues(statusValues);
    getCommentdata(statusValues.req_id);
    form.setFieldsValue({
      claimanalyst_drop: statusValues.client_name,
      status_change: statusValues.status,
    });
    setStatusModal(true);
  };

  const getCommentdata = (req_id) => {
    setLoading(true);
    dispatch(InspectionServices.getComments(req_id))
      .then((res) => {
        setCommentData(res);
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const fetchData = (params = {}) => {
    setLoading(true);
    dispatch(ManufactureRequest.getInspectionManufactureData())
      .then((res) => {
        let active_key = props.activeKey;
        if (active_key === "6") {
          const filtered = res.filter((inspections) => {
            return inspections.status_hidden === "Archived";
          });
          setInspections(filtered);
        } else {
          const filtered = res.filter((inspections) => {
            return inspections.status_hidden !== "Archived" && inspections.status_hidden !== "Cancelled" && inspections.status_hidden !== "Delete";
          });
          setInspections(filtered);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const fetchRemaininginspections = (params = {}) => {
    setLoading(true);
    dispatch(ManufactureRequest.getRemainingInspectionData())
      .then((res) => {
        console.warn("setRemainingInspections", res);
        setRemainingInspections(res);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const fetchClaimAnalystData = (params = {}) => {
    dispatch(getClientdropService.getClaimanalystDrop())
      .then((res) => {
        console.info("claimanalyst_drop", res);
        setclaimAnalystDropdown(res);
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
      });
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const onChangenotifyins = (e) => {
    setCheckedins(e.target.checked);
  };

  const onChangenotifyass = (e) => {
    setCheckedass(e.target.checked);
  };

  const getPdfAttachments = (report_id) => {
    let values = {};
    values.report_id = report_id;

    console.info("getPdfAttachments Data", report_id);
    dispatch(PdfServices.getPdfAttachments(values))
      .then((res) => {
        console.info("getPdfConfidential", res);
        window.open(res, "download");
      })
      .catch((err) => {
        console.info("Get Error Edit State...");
        setLoading(false);
      });
  };

  const viewReport = (action) => {
    enterLoading(0);
    action.category === "Carpet -Basic"
      ? navigate("/single-carpet-basic/?report_id=" + action.report_id)
      : action.category === "Carpet"
        ? navigate("/single-carpet-detailed/?report_id=" + action.report_id)
        : action.category === "Hard Surface -Basic"
          ? navigate("/single-hardwood-basic/?report_id=" + action.report_id)
          : action.category === "LVT"
            ? navigate("/single-lvt/?report_id=" + action.report_id)
            : action.category === "Hardwood"
              ? navigate("/single-hardwood-detailed/?report_id=" + action.report_id)
              : action.category === "Tile"
                ? navigate("/single-tile-report/?report_id=" + action.report_id)
                : action.category === "Laminate"
                  ? navigate("/single-Laminate/?report_id=" + action.report_id)
                  : navigate("/single-quick-report/?report_id=" + action.report_id);
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "S_No.",
      key: "index",
      render: (value, item, index) => <>{(page - 1) * pageSize + index + 1}</>,
    },
    // {
    //   title: "Inspection No.",
    //   dataIndex: "inspection_number",
    //   key: "inspection_number",

    //   sorter: {
    //     compare: (a, b) => a.inspection_number - b.inspection_number,
    //   },
    // },
    {
      title: "Claim #",
      dataIndex: "claim_number",
      key: "claim_number",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          // String(record.inspection_number)
          //   .toLowerCase()
          //   .includes(value.toLowerCase()) ||
          String(record.claim_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ins_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.ins_date)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.date)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.rem_day)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.assigned_to)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.status_hidden)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => {
        a = a.claim_number || "";
        b = b.claim_number || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Inspector Name",
      dataIndex: "ins_name",
      key: "Inspector_name",
      sorter: (a, b) => {
        a = a.Inspector_name || "";
        b = b.Inspector_name || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Inspection Date",
      dataIndex: "ins_date",
      key: "ins_date",

      sorter: (a, b) => {
        a = a.ins_date || "";
        b = b.ins_date || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Request Date",
      dataIndex: "date",
      key: "request_date",

      sorter: (a, b) => {
        a = a.request_date || "";
        b = b.request_date || "";
        return a.localeCompare(b);
      },
    },
    {
      title: "Requested",
      dataIndex: "rem_day",
      key: "Requested",
      sorter: {
        compare: (a, b) => a.rem_day.length - b.rem_day.length,
      },
    },
    {
      title: "Assigned To",
      dataIndex: "assigned_to",
      key: "Assigned_to",
      render: (text) => <>{text}</>,
      sorter: {
        compare: (a, b) => a.assigned_to.length - b.assigned_to.length,
      },
    },
    {
      title: "Status",
      dataIndex: "status_hidden",
      key: "status_hidden",

      sorter: (a, b) => {
        a = a.status_hidden || "";
        b = b.status_hidden || "";
        return a.localeCompare(b);
      },
      render: (text, action) => (
        <>
          <Button
            color="#ffffff"
            className="statusTags"
            onClick={() => openStatusModal(action.status)}
          >
            {action.status_hidden}
          </Button>
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (i, action) => {
        return (
          <div className="actionBtn">
            <IconButton
              icon={<EyeFilled />}
              type="primary"
              tooltip="View"
              text="View"
              handleClick={() =>
                navigate("/single-request/?req_id=" + action.action.req_id)
              }
            />
            {action.action.report_generated === "YES" ? (
              <>
                <IconButton
                  icon={<FileOutlined />}
                  type="primary"
                  tooltip="View Report"
                  text="Report"
                  handleClick={() => viewReport(action.action)}
                />
                {action.action.add_attachments === "Yes" ? (
                  <IconButton
                    icon={<PictureOutlined />}
                    type="primary"
                    tooltip="Photos"
                    text="Photos"
                    handleClick={() =>
                      getPdfAttachments(action.action.report_id)
                    }

                  />
                ) : (
                  ""
                )}
                {action.action.invoice_generated === "YES" ? (
                  <IconButton
                    icon={<BookFilled />}
                    type="primary"
                    tooltip="Invoice"
                    text="Invoice"
                    handleClick={() =>
                      navigate(
                        "/report-invoice/?invoice_id=" +
                        action.action.invoice_id
                      )
                    }
                  />
                ) : (
                  ""
                )}
                <IconButton
                  icon={<MailOutlined />}
                  type="primary"
                  tooltip="Send"
                  text="Send"
                  handleClick={() =>
                    navigate("/send-email/?req_id=" + action.action.req_id)
                  }
                />
              </>
            ) : action.action.request_type === "Flooring dealer" ? (
              <IconButton
                icon={<MailOutlined />}
                type="primary"
                tooltip="Send Decision"
                text="Send Decision"
                handleClick={() =>
                  navigate("/send-decision/?req_id=" + action.action.req_id)
                }
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="tableWrapper">
        <div className="filterWrap">
          <Row gutter={16} justify="space-between">
            <Col md={6} xs={24}>
              <div className="searchWrapper">
                <Input
                  className="formControl"
                  prefix={<SearchOutlined />}
                  allowClear
                  placeholder="Search..."
                  onSearch={(value) => {
                    setSearchedText(value);
                  }}
                  onChange={(e) => {
                    setSearchedText(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={4} xs={24}>
              <div className="filterDropdown text-end">
                <button
                  type="button"
                  className={isActive ? "btnActive filterBtn" : "filterBtn"}
                  onClick={handleClick}
                >
                  Filter <DownOutlined className="dropIcon" />
                </button>
              </div>
            </Col>
            <Col md={24}>
              {isActive ? (
                <div className="filterSection">
                  <Card className="filterCard">
                    <Row gutter={16} justify="center">
                      {/* <Col span={4}>
                        <div className="formGroup">
                          <Input
                            placeholder="Inspection Number"
                            className="formControl"
                            allowClear
                            onSearch={(value) => {
                              setSearchedText(value);
                            }}
                            onChange={(e) => {
                              setSearchedText(e.target.value);
                            }}
                          />
                        </div>
                      </Col> */}
                      <Col span={4}>
                        <div className="formGroup">
                          <Input
                            placeholder="Claim Number"
                            className="formControl"
                            allowClear
                            onSearch={(value) => {
                              setSearchedText(value);
                            }}
                            onChange={(e) => {
                              setSearchedText(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="formGroup">
                          <Select defaultValue="Status" onChange={handleChange}>
                            <Option value="all">All</Option>
                            <Option value="pending">Pending</Option>
                            <Option value="complete">Complete</Option>
                            <Option value="archived">Archived</Option>
                            <Option value="cancelled">Cancelled</Option>
                            <Option value="More info requested">More info requested</Option>
                            
                          </Select>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="formGroup">
                          <DatePicker
                            name="start_date"
                            onChange={onChangeStartDate}
                            placeholder="Date From"
                            format="MM/DD/YYYY"
                          />
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="formGroup">
                          <DatePicker
                            name="end_date"
                            onChange={onChangeEndDate}
                            placeholder="Date To"
                            format="MM/DD/YYYY"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </div>
        <Card className="reportCard">
          <Row gutter={16}>
            <Col>
              <div className="d-flex align-Items-center">
                <p className="tableHeaderText">Remaining Inspections: </p>
                <span>
                  <p className="m-2">
                    {remainingInsoections?.remaining}/
                    {remainingInsoections.total}
                  </p>
                </span>
              </div>
            </Col>
          </Row>

          <Divider />
          <Table
            bordered
            className="inspectionDataTable customInspectionTable"
            tableLayout="auto"
            scroll={{ x: 1000 }}
            columns={columns}
            onChange={onChanges}
            dataSource={
              filterdinspections
                ? filterdinspections
                  : inspections
            }
            rowKey={(record) => record.inspections}
            showSorterTooltip={false}
            pagination={{
              current: page,
              pageSize: pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
              position: ["bottomCenter"],
            }}
            locale={{
              emptyText: loading ? columns.map(u => <Skeleton.Input height={50} style={{ marginTop: '2px', height: '48px', padding: '0px' }} block={true} active={true} />) : <Empty />
            }}
          //   loading={{
          //     indicator: <SkeltonTable />,
          //    spinning: loading,
          //  }}
          />
          {/* Status Modal */}
          <Modal
            title="Change Status"
            centered
            open={statusModal}
            className="inspectionCategoryModal industryModal"
            width={680}
            footer={[
              <Button
                key="submit"
                htmlType="submit"
                className="submitBtn"
                onClick={form.submit}
                loading={loadings[0]}
              >
                Save Changes
              </Button>,
              <Button key="cancel" className="submitBtn" onClick={handleCancel}>
                Cancel
              </Button>,
            ]}
            onCancel={handleCancel}
          >
            <div className="modalContent">
              <div className="formGroup">
                <Form
                  form={form}
                  name="statusForm"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col xs={24} md={12}>
                      <div className="formGroup">
                        <Form.Item
                          label="Claim Analyst"
                          name="claimanalyst_drop"
                        >
                          <Select
                            className="formGroup"
                            defaultValue="Select Claim Analyst"
                          >
                            {claimAnalystDropdown ? (
                              claimAnalystDropdown.map((option, id) => {
                                return (
                                  <Option value={option.id} key={id}>
                                    {option.name}
                                  </Option>
                                );
                              })
                            ) : (
                              <Option value="No data">
                                <Empty />
                              </Option>
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      <div className="formGroup">
                        <Form.Item label="Status" name="status_change">
                          <Select
                            className="formGroup"
                            defaultValue="Change Status"
                          >
                            <Option value="RFQ_accepted">RFQ Accept</Option>
                            <Option value="RFQ_declined">RFQ Decline</Option>
                            <Option value="Pending">Pending</Option>
                            <Option value="Completed">Completed</Option>
                            <Option value="Archived">Archive</Option>
                            <Option value="Delete">Delete</Option>
                            <Option value="Cancelled">Cancelled</Option>
                            <Option value="More info requested">More info requested</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>

                    <Col xs={24} md={24}>
                      <div className="formGroup">
                        <Form.Item label="Comments" name="comment_status">
                          <TextArea
                            placeholder="Comments"
                            className="customTextarea formControl"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={8}>
                      <div className="formGroup">
                        <Form.Item name="notify_ins">
                          <Checkbox
                            onChange={onChangenotifyins}
                            checked={checkedins}
                          >
                            Notify Inspector
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} md={8}>
                      <div className="formGroup">
                        <Form.Item name="notify_assignee">
                          <Checkbox
                            onChange={onChangenotifyass}
                            checked={checkedass}
                          >
                            Notify Claim analyst
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <div className="commentsSection">
                    <div className="notes-show-section">
                      <ul className="notes-list">
                        <ul>
                          {loading ? (
                            <Skeleton active />
                          ) : (
                            <>
                              {commentData &&
                                commentData.data?.map((comments, index) => (
                                  <li className="notes-items" key={index}>
                                    <div className="note-content">
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: comments?.comment_content,
                                        }}
                                      ></p>
                                    </div>
                                    <p className="notes-meta">
                                      {comments?.comment_date}
                                      <span className="mx-2">
                                        {comments?.comment_author}
                                      </span>
                                    </p>
                                  </li>
                                ))}
                            </>
                          )}
                        </ul>
                      </ul>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </Modal>
        </Card>
      </div>
    </div>
  );
}

export default AllManufacturerClaimTable;
